var ComponentAction;
(function (ComponentAction) {
    ComponentAction["DOC_DETAIL"] = "docDetail";
    ComponentAction["DOC_URL"] = "docUrl";
    ComponentAction["METRIC_DETAIL"] = "metricDetail";
    ComponentAction["TYPEAHEAD_DOC_SELECTED"] = "typeaheadDocSelected";
    ComponentAction["RANGE_SELECTED"] = "rangeSelected";
    ComponentAction["CUSTOM_RANGE_SELECTED"] = "customRangeSelected";
    ComponentAction["EDIT_METRIC"] = "edit-metric";
    ComponentAction["RULE_ACTION"] = "rule-action";
})(ComponentAction || (ComponentAction = {}));

export { ComponentAction as C };
